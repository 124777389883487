import React from 'react';
import { Box, CI, colorChip, HStack, Text } from '@design-system/pc';
import useHome from './hooks/useHome';
import Footer from '@/ui/Footer';
import { ClickableBox } from '@/components/ClickableBox';
import { Autoplay, EffectCreative, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/effect-creative';
import styled from 'styled-components';
import { LAYOUT_MAX_WIDTH, BASE_Z_INDEX } from '@/styles';
import TrackingService from '@/services/tracking';
import { GetTemplateComponentsType } from '@display-component/pc/dist/types/display-service';
import { MainPopup } from '@/components/MainPopup';

export interface HomeProps {
  data: GetTemplateComponentsType[];
}

const Home = ({ data }: HomeProps) => {
  const {
    activeCategory,
    displayGTM,
    welcomeBannerList,
    mallMenus,
    remodelingMenus,
    handleChangeSwiper,
    handleGoLink,
    handleClickBigCategory,
    handleClickTopButton,
    swiperRef,
  } = useHome({ data });

  return (
    <StyledWrapper
      className="main-wrapper"
      {...TrackingService.tagGTM(displayGTM?.tagGTM || '', displayGTM)}
    >
      <Swiper
        ref={swiperRef}
        direction="vertical"
        effect="creative"
        grabCursor={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        mousewheel={{
          releaseOnEdges: true,
        }}
        creativeEffect={{
          prev: {
            translate: [0, 0, 0],
          },
          next: {
            translate: [0, '100%', 1],
          },
        }}
        speed={800}
        modules={[Autoplay, EffectCreative, Mousewheel]}
        onActiveIndexChange={(e) => handleChangeSwiper(e.activeIndex)}
      >
        {welcomeBannerList?.length > 0 &&
          welcomeBannerList.map((item) => {
            return (
              <StyledSwiperSlide
                key={`slide-${item.sortSeq}`}
                onClick={() => handleGoLink(item.appLinkInfoDto?.linkUrl)}
                {...TrackingService.tagGTM(
                  `${displayGTM?.tagGTM}_button`,
                  displayGTM,
                )}
              >
                <ClickableBox width="100%" height="100%">
                  <HStack
                    width="100%"
                    height="100%"
                    backgroundImage={`url('${item.imageUrl}')`}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    alignment="end"
                    space="center"
                  >
                    <Box width={LAYOUT_MAX_WIDTH} mb={64} pl={100}>
                      {item.bannerTitle1 && (
                        <Text
                          variant="etc01"
                          color="color_chip.white"
                          textAlign="left"
                          className="main_title"
                        >
                          {item.bannerTitle1}
                        </Text>
                      )}
                      {item.bannerTitle2 && (
                        <Text
                          variant="etc01"
                          color="color_chip.white"
                          textAlign="left"
                          className="main_title"
                        >
                          {item.bannerTitle2}
                        </Text>
                      )}
                      {item.bannerSubTitle1 && (
                        <Text
                          variant="subTitle4"
                          color="color_chip.white"
                          textAlign="left"
                          maxWidth="calc(100% - 44px)"
                          className="main_subtitle"
                          mt={12}
                        >
                          {item.bannerSubTitle1}
                        </Text>
                      )}
                      {item.bannerSubTitle2 && (
                        <Text
                          variant="subTitle4"
                          color="color_chip.white"
                          textAlign="left"
                          maxWidth="calc(100% - 44px)"
                          className="main_subtitle"
                        >
                          {item.bannerSubTitle2}
                        </Text>
                      )}
                    </Box>
                  </HStack>
                </ClickableBox>
              </StyledSwiperSlide>
            );
          })}
        {welcomeBannerList?.length > 0 && (
          <StyledSwiperSlide>
            <HStack
              width="100%"
              height="100%"
              backgroundColor="color_chip.primary"
              alignment="end"
            >
              <Footer />
            </HStack>
          </StyledSwiperSlide>
        )}
      </Swiper>
      <ClickableBox
        position="absolute"
        top={132}
        left={`calc(50% - ${LAYOUT_MAX_WIDTH / 2}px)`}
        ml={100}
        zIndex={BASE_Z_INDEX}
      >
        <CI
          variant="CI_signature_white"
          resize
          width={753.88}
          height={72}
          mb={49}
          onClick={handleClickTopButton}
          {...TrackingService.tagGTM('main_logo_button')}
        />
        <HStack>
          <ClickableBox
            position="relative"
            onMouseOver={() => handleClickBigCategory(1)}
            onMouseLeave={() => handleClickBigCategory(0)}
          >
            <Text
              variant="headline3"
              color={
                activeCategory !== 2
                  ? 'color_chip.white'
                  : 'dimmed.light.dimmed_70'
              }
            >
              홈퍼니싱
            </Text>
            {activeCategory === 1 && (
              <MenuWrapper position="absolute" top="100%" left="50%" pt="5px">
                <MenuBox
                  py={16}
                  backgroundColor="color_chip.white"
                  textAlign="center"
                  maxHeight={518}
                  minWidth={162}
                  maxWidth={220}
                  overflow="auto"
                >
                  {mallMenus.length > 0 &&
                    mallMenus.map((menu, idx) => {
                      return (
                        <StyledClickableBox
                          py="8px"
                          px={24}
                          key={menu.appLinkInfoDto?.appLinkVal}
                          onClick={() =>
                            menu.appLinkInfoDto?.linkUrl &&
                            handleGoLink(menu.appLinkInfoDto?.linkUrl)
                          }
                          {...TrackingService.tagGTM('main_category_button', {
                            'category-id': menu.appLinkInfoDto?.appLinkVal,
                          })}
                        >
                          <Text
                            variant={idx === 0 ? 'headline4' : 'subTitle4'}
                            color="color_chip.primary"
                          >
                            {menu.menuDataNm}
                          </Text>
                        </StyledClickableBox>
                      );
                    })}
                </MenuBox>
              </MenuWrapper>
            )}
          </ClickableBox>
          <Box
            width="1px"
            height={16}
            backgroundColor="dimmed.light.dimmed_60"
            mx={16}
          ></Box>
          <ClickableBox
            position="relative"
            onMouseOver={() => handleClickBigCategory(2)}
            onMouseLeave={() => handleClickBigCategory(0)}
          >
            <Text
              variant="headline3"
              color={
                activeCategory !== 1
                  ? 'color_chip.white'
                  : 'dimmed.light.dimmed_70'
              }
            >
              인테리어
            </Text>
            {activeCategory === 2 && (
              <MenuWrapper position="absolute" top="100%" left="50%" pt="5px">
                <MenuBox
                  py={16}
                  backgroundColor="color_chip.white"
                  textAlign="center"
                  maxHeight={518}
                  minWidth={162}
                  maxWidth={220}
                  overflow="auto"
                >
                  {remodelingMenus.length > 0 &&
                    remodelingMenus.map((menu, idx) => {
                      return (
                        <StyledClickableBox
                          py="8px"
                          px={24}
                          key={menu.appLinkInfoDto?.appLinkVal}
                          onClick={() =>
                            menu.appLinkInfoDto?.linkUrl &&
                            handleGoLink(menu.appLinkInfoDto?.linkUrl)
                          }
                          {...TrackingService.tagGTM('main_category_button', {
                            'category-id': menu.appLinkInfoDto?.appLinkVal,
                          })}
                        >
                          <Text
                            variant={idx === 0 ? 'headline4' : 'subTitle4'}
                            color="color_chip.primary"
                          >
                            {menu.menuDataNm}
                          </Text>
                        </StyledClickableBox>
                      );
                    })}
                </MenuBox>
              </MenuWrapper>
            )}
          </ClickableBox>
        </HStack>
      </ClickableBox>

      <MainPopup />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  height: 100vh;
  position: relative;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  .main_title,
  .main_subtitle {
    opacity: 0;
    transition-delay: 0s;
    transform: translateY(40px);
    overflow: hidden;
  }

  &.visible_main_text .main_title,
  &.visible_sub_text .main_subtitle {
    transition: all 0.4s ease-out 0.3s;
    opacity: 1;
    transform: translateY(0);
  }
`;

const MenuWrapper = styled(Box)`
  transform: translateX(-50%);
`;

const MenuBox = styled(Box)`
  overscroll-behavior: contain;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
`;

const StyledClickableBox = styled(ClickableBox)`
  &:hover {
    background-color: ${colorChip.gray[100]};
  }
`;

export default Home;
